import api from "../config/api.js";

const fetchData = async ({
  page,
  sortBy,
  order,
  limit,
  search,
  patient,
  professional,
  status,
  startDate,
  endDate,
}) => {
  const response = await api.get(
    `/clinic-appointments?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&patient=${patient}&professional=${professional}&status=${status}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};

const submitData = async (data) => {
  if (!data._id) {
    try {
      const response = await api.post("/clinic-appointments", data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  } else {
    try {
      const response = await api.put(`/clinic-appointments/${data._id}`, data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  }
};

const fetchMyData = async ({
  page,
  sortBy,
  order,
  limit,
  search,
  patient,
  status,
  startDate,
  endDate,
}) => {
  const response = await api.get(
    `/clinic-appointments/my-appointments?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&patient=${patient}&status=${status}&startDate=${startDate}&endDate=${endDate}`
  );
  return response.data;
};

const attendAppointment = async (data) => {
  try {
    const response = await api.put(
      `/clinic-appointments/${data._id}/attend`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const getAvailableYearsAndMonths = async () => {
  try {
    const response = await api.get("/clinic-appointments-periods-select");
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  fetchData,
  submitData,
  fetchMyData,
  attendAppointment,
  getAvailableYearsAndMonths,
};
export default apiMethods;
