import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import patientService from "../../../../services/clinicPatientService";
import exportFileService from "../../../../services/exportFileService";
import { ButtonCallToACtion } from "../../../../components/Buttons";
import Swal from "sweetalert2";
import { dateFormat } from "../../../../config/utils";

function Evolutions() {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [item, setItem] = useState({});
  const [withComments, setWithComments] = useState(false);
  const [withSignature, setWithSignature] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const patients = await patientService.getPatients();
    setPatients(patients);
    setLoading(false);
  }, []);

  useEffect(() => {
    document.title = "We Care Sync - Evolução";
    fetchData();
  }, [fetchData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "patient") {
      setPatientName(
        patients.find((patient) => patient._id === value).fullName
      );
      setItem({ ...item, [name]: value });
    }
    setItem({ ...item, [name]: value });
  };

  const submitData = async (type) => {
    setLoading(true);
    if (!item.patient || !item.startDate || !item.endDate) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Preencha todos os campos obrigatórios!",
      });
      setLoading(false);
      return;
    }
    try {
      const exportData = { ...item, type };
      const fileName = `${patientName} - Evolução - ${dateFormat(
        item.startDate
      )} até ${dateFormat(item.endDate)}`;
      await exportFileService.exportFileClinicAppointments(
        exportData,
        fileName,
        withComments,
        withSignature
      );
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: `Arquivo ${type.toUpperCase()} exportado com sucesso!`,
      });
    } catch (error) {
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result);
            const errorMessage =
              errorData.message || "Ocorreu um erro inesperado.";
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: errorMessage,
            });
          } catch (e) {
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Não foi possível processar a mensagem de erro.",
            });
          }
        };
        reader.onerror = () => {
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao ler a resposta do servidor.",
          });
        };
        reader.readAsText(error.response.data);
      } else {
        const errorMessage = error.message || "Ocorreu um erro inesperado.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar pageName="Evoluções" pageUrl="/evolucoes">
        <Container fluid>
          <Row className="mt-3 mb-4">
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <h3>Extraia as evoluções dos seus pacientes aqui!</h3>
            </Col>
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <div className="form-data">
                <Row className="align-items-center justify-content-center px-5">
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Paciente
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="patient"
                        defaultValue={item.patient || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>
                          Selecione o Paciente
                        </option>
                        {patients.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.fullName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Data de Início
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        onChange={handleInputChange}
                        defaultValue={item.startDate || ""}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Data de Fim
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        onChange={handleInputChange}
                        defaultValue={item.endDate || ""}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex align-items-center justify-content-center gap-3"
                  >
                    <Form.Group className="mb-3 mb-md-2 mt-2">
                      <Form.Check
                        type="checkbox"
                        name="withComments"
                        label="Incluir comentários"
                        defaultValue={withComments}
                        onChange={(e) => setWithComments(e.target.checked)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 mb-md-2 mt-2">
                      <Form.Check
                        type="checkbox"
                        name="withSignature"
                        label="Incluir assinaturas"
                        defaultValue={withSignature}
                        onChange={(e) => setWithSignature(e.target.checked)}
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center"
                  >
                    <ButtonCallToACtion
                      onClick={() => submitData("pdf")}
                      btnText={"EXTRAIR .PDF"}
                      className="mx-2"
                    />
                    <ButtonCallToACtion
                      onClick={() => submitData("docx")}
                      btnText={"EXTRAIR .DOCX"}
                      className="mx-2"
                    />
                    <ButtonCallToACtion
                      onClick={() => submitData("html")}
                      btnText={"EXTRAIR .HTML"}
                      className="mx-2"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Evolutions;
