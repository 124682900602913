import api from "../config/api.js";

const exportFileClinicAppointments = async (
  data,
  name,
  comments = "false",
  signatures = "false"
) => {
  try {
    const response = await api.get(
      `/export-files/clinic-appointments?patient=${data.patient}&startDate=${data.startDate}&endDate=${data.endDate}&type=${data.type}&withComments=${comments}&withSignature=${signatures}`,
      {
        responseType: "blob",
      }
    );

    const fileName = response.headers["content-disposition"]
      ? response.headers["content-disposition"].split("filename=")[1]
      : `${name}.${data.type}`;

    const blob = new Blob([response.data], { type: response.data.type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const exportFileClinicGuideServices = async (data, name) => {
  try {
    const response = await api.get(
      `/export-files/clinic-service-guides?patient=${data.patient}&professional=${data.professional}&month=${data.month}&year=${data.year}&type=${data.type}`,
      {
        responseType: "blob",
      }
    );

    const fileName = response.headers["content-disposition"]
      ? response.headers["content-disposition"].split("filename=")[1]
      : `${name}.${data.type}`;

    const blob = new Blob([response.data], { type: response.data.type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const apiMethods = {
  exportFileClinicAppointments,
  exportFileClinicGuideServices,
};
export default apiMethods;
