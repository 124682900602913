import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import patientService from "../../../../services/clinicPatientService";
import roleService from "../../../../services/clinicRoleService";
import appointmentService from "../../../../services/clinicAppointmentService";
import exportFileService from "../../../../services/exportFileService";
import { ButtonCallToACtion } from "../../../../components/Buttons";
import Swal from "sweetalert2";

function ServiceGuides() {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [item, setItem] = useState({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      roleService.getProfessionals(),
      patientService.getPatients(),
      appointmentService.getAvailableYearsAndMonths(),
    ]).then((values) => {
      setProfessionals(values[0]);
      setPatients(values[1]);
      setAvailableDates(values[2].availableDates);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    document.title = "We Care Sync - Guias de Serviços Profissionais";
    fetchData();
  }, [fetchData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const submitData = async (type) => {
    setLoading(true);

    if (!item.patient || !item.professional || !item.availability) {
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Preencha todos os campos obrigatórios!",
      });
      setLoading(false);
      return;
    }

    const [year, month] = item.availability.split("_").map(Number);

    try {
      const exportData = {
        ...item,
        year,
        month,
        type,
      };

      const professionalName = professionals.find(
        (professional) => professional._id === item.professional
      ).user.fullName;
      const patientName = patients.find(
        (patient) => patient._id === item.patient
      ).fullName;
      const monthLabel =
        availableDates.find(
          (date) => date.month === month && date.year === year
        )?.monthLabel || "Mês";

      const fileName = `Guia de Serviço - ${patientName} - ${professionalName} - ${monthLabel} - ${year}`;

      await exportFileService.exportFileClinicGuideServices(
        exportData,
        fileName
      );

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: `Arquivo ${type.toUpperCase()} exportado com sucesso!`,
      });
    } catch (error) {
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorData = JSON.parse(reader.result);
            const errorMessage =
              errorData.message || "Ocorreu um erro inesperado.";
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: errorMessage,
            });
          } catch (e) {
            Swal.fire({
              icon: "error",
              title: "Erro!",
              text: "Não foi possível processar a mensagem de erro.",
            });
          }
        };
        reader.onerror = () => {
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Erro ao ler a resposta do servidor.",
          });
        };
        reader.readAsText(error.response.data);
      } else {
        const errorMessage = error.message || "Ocorreu um erro inesperado.";
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar
        pageName="Guias de Serviços Profissionais"
        pageUrl="/guias-de-servico"
      >
        <Container fluid>
          <Row className="mt-3 mb-4">
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <h3>Extraia as guias de serviços profissionais da clínica!</h3>
            </Col>
            <Col xs={12} md={12} className="d-flex justify-content-center">
              <div className="form-data">
                <Row className="align-items-center justify-content-center">
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Paciente
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="patient"
                        defaultValue={item.patient || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>
                          Selecione o Paciente
                        </option>
                        {patients.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.fullName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Profissional
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="professional"
                        defaultValue={item.professional || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>
                          Selecione o Profissional
                        </option>
                        {professionals.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.user.fullName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group className="mb-3 mb-md-2">
                      <Form.Label className="text-uppercase">
                        Período
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="availability"
                        defaultValue={item.availability || ""}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>
                          Selecione o Período
                        </option>
                        {availableDates.map((item, index) => (
                          <option
                            value={`${item.year}_${item.month}`}
                            key={index}
                          >
                            {item.monthLabel}/{item.year}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center"
                  >
                    <ButtonCallToACtion
                      onClick={() => submitData("pdf")}
                      btnText={"EXTRAIR .PDF"}
                      className="mx-2"
                    />
                    <ButtonCallToACtion
                      onClick={() => submitData("html")}
                      btnText={"EXTRAIR .HTML"}
                      className="mx-2"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default ServiceGuides;
